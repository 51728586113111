import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Edit, Trash } from "react-feather";
import { Calendar } from "react-calendar";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "components/card";
import DataTable from "components/dataTable/reactDataTable";
import InputField from "components/fields/InputField";
import { Button } from "components/ui-elements/Button";
import { get, } from "services/api_Helpers";
import { errorToast, successToast } from "utils";
import EditModal from "./CouponEditModal";
import DeleteCoupon from "./deleteCoupon";
import { deleted } from "services/api_Helpers";


const AllCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await get('/coupon', { params: filters });
      setCoupons(response.data.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values) => {
    const filters = {
      title: values.title,
      startDate: values.date ? moment(values.date).format("YYYY-MM-DD") : undefined,
    };
    fetchCoupons(filters);
  };

  const handleEditCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    setEditModalVisible(true);
  };

  const handleUpdateCoupon = () => {
    fetchCoupons();
    setEditModalVisible(false);
    setSelectedCoupon(null);
  };

  const handleDeleteCoupon = async (id) => {
    console.log('handleDeleteCoupon called with id:', id);
    if (selectedCoupon) {
      console.log('selectedCoupon...', selectedCoupon);
      try {
        await deleted(`/coupon/${selectedCoupon._id}`);
        setCoupons(coupons.filter(c => c._id !== selectedCoupon._id));
        successToast("Coupon deleted successfully!");
      } catch (error) {
        errorToast(error.message);
      } finally {
        setDeleteModalVisible(false);
        setSelectedCoupon(null);
      }
    } else {
      console.error('No selectedCoupon found');
    }
  };
  

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const columns = [
    {
      name: "Coupon Title",
      maxWidth: "350px",
      selector: (row) => (
        <div className="flex items-center gap-2 py-2">
          <div>
            <p className="font-semibold">{row?.title}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Coupon Code",
      selector: (row) => <span>{row.couponCode}</span>,
    },
    {
      name: "Discount Percentage",
      selector: (row) => <span>{row.discountPercentage}%</span>,
    },
    {
      name: "Start Date",
      selector: (row) => <span>{moment(row.startDate).format("DD-MM-YYYY")}</span>,
    },
    {
      name: "Expiry Date",
      selector: (row) => <span>{moment(row.expiryDate).format("DD-MM-YYYY")}</span>,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex gap-3">
          <Edit
            className="cursor-pointer"
            onClick={() => handleEditCoupon(row)}
          />
          <Trash
            className="cursor-pointer"
            onClick={() => {
              setSelectedCoupon(row);
              setDeleteModalVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">All Coupons</h4>
          <Link to="/admin/add-coupon">
            <Button>Add Coupon</Button>
          </Link>
        </div>

        <div className="px-3">
          <Formik
            initialValues={{ title: "", date: new Date() }}
            onSubmit={handleSearch}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-3 pt-5">
                  <div>
                    <Field
                      label="Search By Coupon Title"
                      name="title"
                      type="text"
                      placeholder="Enter coupon title"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      className="form-label ml-3 block text-sm font-bold text-navy-700 dark:text-white"
                      htmlFor="basic-default-fullname"
                    >
                      Search By Date
                    </label>
                    <input
                      type="text"
                      readOnly
                      onClick={toggleCalendar}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                      value={values.date ? moment(values.date).format("YYYY-MM-DD") : ""}
                    />
                    {isCalendarVisible && (
                      <Card extra="flex w-[300px] z-20 flex-col px-3 py-3 !absolute">
                        <Calendar
                          onChange={(e) => {
                            toggleCalendar();
                            setFieldValue("date", e);
                          }}
                          value={values.date}
                          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                          view={"month"}
                        />
                      </Card>
                    )}
                  </div>
                  <div className="flex items-end">
                    <Button type="submit">Search</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <DataTable
          loadingMessage="Loading..."
          isLoading={loading}
          columns={columns}
          data={coupons}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />

        {/* Custom Delete Modal */}
        {isDeleteModalVisible && (
          <DeleteCoupon
            id={selectedCoupon._id}
            onDelete={handleDeleteCoupon}
            message="Are you sure you want to delete this coupon?"
            onClose={() => setDeleteModalVisible(false)}
          />
        )}

        {isEditModalVisible && (
          <EditModal
            coupon={selectedCoupon}
            onClose={() => setEditModalVisible(false)}
            onUpdate={handleUpdateCoupon}
          />
        )}
      </Card>
    </>
  );
};

export default AllCoupons;
