

import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyEditor = ({ editorData = '', onChange }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onReady={editor => {
                console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                onChange(data);
            }}
            config={{
                toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'underline', '|',
                    'numberedList', 'bulletedList', '|',
                    'blockQuote', 'link', 'insertImage', '|',
                    'mediaEmbed',  
                    'insertTable', '|', 'alignment:left', 'alignment:center', 'alignment:right', '|',
                    'undo', 'redo'
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                    ]
                },
                alignment: {
                    options: ['left', 'center', 'right']
                },
                image: {
                    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],  // Optional image toolbar
                },
                // Enable Base64 image uploads
                extraPlugins: [MyCustomUploadAdapterPlugin]
            }}
        />
    );
};

// Custom plugin to use Base64 Upload Adapter
function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyCustomUploadAdapter(loader);
    };
}

class MyCustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve({ default: reader.result });
                reader.onerror = error => reject(error);
            }));
    }

    // Aborts the upload process.
    abort() {
        // Reject the promise if the upload was aborted
    }
}

export default MyEditor;
