import React from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

import DatePickerField from "components/fields/DatePickerField";
import { successToast, errorToast } from "utils";
import { useNavigate } from "react-router-dom";
import Card from "components/card";
import { postForm } from "services/api_Helpers";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "components/common/error-msg";
import InputField from "components/fields/InputField";
import { post } from "services/api_Helpers";

const AddCoupons = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const startDate = new Date(values.startDate).toISOString();
      const expiryDate = new Date(values.expiryDate).toISOString();

      const couponData = {
        title: values.title,
        couponCode: values.couponCode,
        discountPercentage: values.discountPercentage,
        startDate: startDate,
        expiryDate: expiryDate,
        noOfCoupons: values.noOfCoupons,
      };

      const res = await post("/coupon", couponData);
      successToast(res.data.message);
      resetForm();
      navigate("/admin/all-coupons");
    } catch (error) {
      errorToast(error.response?.data?.message || error.message);
    }
  };
  

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    couponCode: Yup.string().required("Coupon Code is required"),
    discountPercentage: Yup.number()
      .required("Discount Percentage is required")
      .min(1, "Discount Percentage must be at least 1%")
      .max(100, "Discount Percentage cannot exceed 100%"),
    startDate: Yup.date().required("Start Date is required"),
    expiryDate: Yup.date().required("Expiry Date is required"),
    noOfCoupons: Yup.number()
      .required("Number of Coupons is required")
      .positive("Number of Coupons must be a positive number")
      .integer("Number of Coupons must be an integer"),
  });

  return (
    <Card extra="flex flex-col w-full h-full mt-4">
      <div className="flex items-center justify-between bg-white rounded-t-2xl px-4 py-4 shadow-2xl dark:bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add New Coupon
        </h4>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-5">
        <Formik
          initialValues={{
            title: "",
            couponCode: "",
            discountPercentage: "",
            startDate: null,
            expiryDate: null,
            noOfCoupons: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <div className="px-3 py-3">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Title"
                        placeholder="Enter Coupon Title"
                        type="text"
                        name="title"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.title} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Coupon Code"
                        placeholder="Enter Coupon Code"
                        type="text"
                        name="couponCode"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.couponCode} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Discount Percentage"
                        placeholder="Enter Discount Percentage"
                        type="number"
                        name="discountPercentage"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.discountPercentage} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Number of Coupons"
                        placeholder="Enter Number of Coupons"
                        type="number"
                        name="noOfCoupons"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.noOfCoupons} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label style={{fontWeight:'600', fontSize:'16px', marginLeft:'10px'}}>Start Date</label>
                      <Field
                        name="startDate"
                        component={DatePickerField}
                        placeholderText="Select Start Date"
                      />
                      <ErrorMessage error={errors.startDate} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                    <label style={{fontWeight:'600', fontSize:'16px', marginLeft:'10px'}}>End Date</label>

                      <Field
                        name="expiryDate"
                        component={DatePickerField}
                        placeholderText="Select Expiry Date"
                      />
                      <ErrorMessage error={errors.expiryDate} />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="submit"
                    className="rounded-lg bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    disabled={isSubmitting}
                  >
                    Add Coupon
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default AddCoupons;
