import React from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import { successToast, errorToast } from "utils";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "components/common/error-msg";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import { post } from "services/api_Helpers";

const AddPrice = (props) => {
  const navigate = useNavigate();

  // Validation schema for the form
  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    currency: Yup.string().required("Currency is required"),
  });

  // Options for currency selection
  const currencyOptions = [
    { label: "EUR", value: "EUR" },
  ];

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await post("/price", values); // Adjust the API URL as needed
      successToast(response.data.message);
      resetForm();
      navigate("/admin/prices"); // Redirect to the prices list page after successful submission
    } catch (error) {
      errorToast(error.response?.data?.message || "An error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add New Price
        </h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <Formik
          initialValues={{ price: "", currency: "EUR" }} // Default currency set to EUR
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                <div className="px-3 py-3">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Field
                          label="Price"
                          placeholder="Enter your price"
                          type="number"
                          name="price"
                          component={InputField}
                        />
                        {errors.price && touched.price ? (
                          <ErrorMessage error={errors.price} />
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <label
                          className="text-sm text-navy-700 dark:text-white ml-3 font-bold mb-2"
                          htmlFor="currency"
                        >
                          Currency
                        </label>
                        <Field
                          name="currency"
                          selectorOptions={currencyOptions}
                          selectorLabelKey="label"
                          selectorValueKey="value"
                          placeholder="Select currency"
                          component={SelectField}
                          // Make sure to set default value to EUR
                          disabled
                        />
                        {errors.currency && touched.currency ? (
                          <ErrorMessage error={errors.currency} />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      {isSubmitting ? "Adding..." : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default AddPrice;
