import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Edit, Trash, Loader } from "react-feather";
import { successToast, errorToast } from "utils";
import Card from "components/card";
import { get, del, put } from "services/api_Helpers";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";

const Price = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
  ];

  const fetchPrices = async () => {
    try {
      const response = await get("/price");
      setPrices(response.data?.data);
    } catch (error) {
      errorToast("Failed to load prices");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const handleDelete = async () => {
    try {
      await del(`/price/${selectedPriceId}`);
      successToast("Price deleted successfully");
      setPrices(prices.filter((price) => price._id !== selectedPriceId));
    } catch (error) {
      errorToast("Failed to delete price");
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleEdit = (price) => {
    setSelectedPrice(price);
    setSelectedPriceId(price._id);
    setIsUpdateModalOpen(true);
  };

  const handleUpdate = async (values) => {
    try {
      await put(`/price/${selectedPriceId}`, values);
      successToast("Price updated successfully");
      fetchPrices();
    } catch (error) {
      errorToast("Failed to update price");
    } finally {
      setIsUpdateModalOpen(false);
    }
  };

  const validationSchema = Yup.object({
    price: Yup.number().required("Price is required").positive("Price must be positive"),
    currency: Yup.string().required("Currency is required"),
  });

  if (loading) return <Loader />;

  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">Price List</h4>
        <Link
          to="/admin/add-price"
          className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
        >
          Add New Price
        </Link>
      </div>
      
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <div className="px-3 py-3">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Price</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Currency</th>
                <th className="relative px-6 py-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {prices.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center">No prices found.</td>
                </tr>
              ) : (
                prices.map((price) => (
                  <tr key={price._id}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 dark:text-white">{price.price}</td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 dark:text-white">{price.currency}</td>
                    <td className="px-6 py-4 flex space-x-2">
                      <button
                        className="text-blue-600 hover:text-blue-900"
                        onClick={() => handleEdit(price)}
                      >
                        <Edit />
                      </button>
                      <button
                        className="text-red-600 hover:text-red-900"
                        onClick={() => {
                          setSelectedPriceId(price._id);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <Trash />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Update Price Modal */}
      {isUpdateModalOpen && (
        <Modal isOpen={isUpdateModalOpen} toggle={() => setIsUpdateModalOpen(false)}>
          <ModalHeader toggle={() => setIsUpdateModalOpen(false)}>Update Price</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={selectedPrice || { price: "", currency: "" }}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            >
              {({ values, errors, handleChange, handleBlur, isSubmitting }) => (
                <Form>
                  <FormGroup>
                    <InputField
                      label="Price"
                      placeholder="Enter Price"
                      type="number"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="price" component="div" className="text-red-600" />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="text-sm text-navy-700 dark:text-white ml-3 font-bold mb-2"
                      htmlFor="currency"
                    >
                      Currency
                    </label>
                    <Field
                      name="currency"
                      as="select"
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-select block w-full mt-1"
                    >
                      <option value="" disabled>Select Currency</option>
                      {currencyOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="currency" component="div" className="text-red-600" />
                  </FormGroup>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
          <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this price?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
            <Button color="danger" onClick={handleDelete}>Delete</Button>
          </ModalFooter>
        </Modal>
      )}
    </Card>
  );
};

export default Price;
