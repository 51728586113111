import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import MyEditor from "components/MyEditor";
import DropZone from "components/dropzone";
import { put } from "services/api_Helpers";
import { X } from "react-feather";

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const EditModal = ({ blog, onClose, onUpdate }) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    status: "",
    content: "",
    createdAt: new Date(),
    images: null,
  });

  useEffect(() => {
    if (blog) {
      setInitialValues({
        title: blog.title,
        status: blog.status,
        content: blog.content,
        createdAt: new Date(blog.createdAt),
        images: blog.images ? `https://yourownaffirmations.com/backend/${blog.images}` : null, // Updated image URL
      });
    }
  }, [blog]);

  const handleUpdate = async (values) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("status", values.status);
      formData.append("content", values.content);
      formData.append("createdAt", values.createdAt);
  
      // Append the image if it exists
      if (values.images) {
        formData.append("images", values.images); // Change 'image' to 'images'
      }
  
      await put(`/blogs/${blog._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };
  

  return (
    <div className="fixed inset-0 flex items-center justify-center mt-20 z-100">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-4xl max-h-[80vh] overflow-y-auto relative">
        <button className="absolute top-3 right-3" onClick={onClose}>
          <X className="text-gray-500 hover:text-gray-800" size={24} />
        </button>

        <h3 className="text-2xl font-bold mb-4">Edit Blog</h3>
        <Formik initialValues={initialValues} enableReinitialize onSubmit={handleUpdate}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 gap-4">
                <Field
                  label="Title"
                  name="title"
                  type="text"
                  placeholder="Enter blog title"
                  component={InputField}
                />
                <Field
                  name="status"
                  selectorOptions={statusOptions}
                  selectorLabelKey="label"
                  selectorValueKey="value"
                  placeholder="Select status"
                  component={SelectField}
                />
                
                <div>
                  <label className="block text-sm font-bold text-gray-900 dark:text-white">
                    Content
                  </label>
                  <div className="border rounded overflow-y-auto">
                    <MyEditor
                      editorData={values.content}
                      onChange={(val) => setFieldValue("content", val)}
                    />
                  </div>
                </div>

                <div>
                  {console.log('valuesvalues', values)}
                  <DropZone
                    setFieldValue={setFieldValue}
                    values={values}
                    label="Upload New Image"
                    name="images"
                    multiple={false}
                    accept={{
                      "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                    }}
                    previewImage={values.images} 
                  />
                </div>

                <div className="flex justify-end gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditModal;
