import { useState } from "react";

export default () => {
  const [tableScrolling, setTableScrolling] = useState(true);

  const handleTableScroll = (e) => {
    const { scrollWidth, scrollLeft, offsetWidth } = e.target;
    const calculatedScroll = scrollLeft + offsetWidth + 1;
    if (scrollWidth <= calculatedScroll) {
      return setTableScrolling(false);
    } else {
      return setTableScrolling(true);
    }
  };

  return { tableScrolling, handleTableScroll };
};
