import React, { useState, useEffect } from "react";
import { Edit, Trash } from "react-feather";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "components/card";
import DataTable from "components/dataTable/reactDataTable";
import InputField from "components/fields/InputField";
import DeleteBlog from "./deleteBlog";
import { Button } from "components/ui-elements/Button";
import SelectField from "components/fields/SelectField";
import { get } from "services/api_Helpers";
import EditModal from "./BlogEditModal";
import { deleted } from "services/api_Helpers";
import { baesURL } from "services/api_Helpers";
import { ImagebaesURL } from "services/api_Helpers";

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async (filters = {}) => {
    setLoading(true);
    try {
      // Construct query parameters
      const queryParams = new URLSearchParams(filters).toString();
      const response = await get(`/blogs?${queryParams}`);
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values) => {
    const filters = {
      title: values.title || '',
      status: values.status || 'active', // Only add status if it’s specified
    };
    fetchBlogs(filters); // Now it will use query params in the URL
  };

  const handleEditBlog = (blog) => {
    setSelectedBlog(blog);
    setEditModalVisible(true);
  };

  const handleUpdateBlog = () => {
    fetchBlogs(); // Refresh the blog list after update
    setEditModalVisible(false);
    setSelectedBlog(null);
  };

  const handleDeleteBlog = async () => {
    try {
      await deleted(`/blogs/${selectedBlog._id}`);
      setBlogs(blogs.filter(b => b._id !== selectedBlog._id));
      console.log("Blog deleted:", selectedBlog);
    } catch (error) {
      console.error("Error deleting blog:", error);
    } finally {
      setDeleteModalVisible(false);
      setSelectedBlog(null);
    }
  };
  console.log("process.env.REACT_IMAGE_URL,", process.env.REACT_APP_IMAGE_URL)
  const columns = [
    {
      name: "Blog Name",
      maxWidth: "350px",
      selector: (row) => (
        <div className="flex items-center gap-2 py-2">
          {console.log("jnjnjnjn", `http://localhost:5000/${row.images}`)}
          <img
            className="h-14 w-14 rounded"
            src={row.images ? `${ImagebaesURL}${row.images}` : '/assets/images/notfound.svg'}
            onError={(e) => {
              e.target.src = "/assets/images/notfound.svg";
            }}
            alt="blog"
          />
          <div>
            <p className="font-semibold">{row?.title}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => <span>{moment(row.createdAt).format("DD-MM-YYYY HH:mm")}</span>,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <span
            className={`${row.status === "active" ? "badge-success" : "badge-danger"} capitalize`}
          >
            {row.status}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex gap-3">
          <Edit
            className="cursor-pointer"
            onClick={() => handleEditBlog(row)}
          />
          <Trash
            className="cursor-pointer"
            onClick={() => {
              setSelectedBlog(row);
              setDeleteModalVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">All Blogs</h4>
          <Link to="/admin/add-blog">
            <Button>Add Blog</Button>
          </Link>
        </div>

        <div className="px-3">
          <Formik
            initialValues={{ title: "", status: "active" }} // Set default status to 'active'
            onSubmit={handleSearch}
          >
            {({
              values,
              setFieldValue,
            }) => (
              <Form>
                <div className="grid grid-cols-4 gap-3 pt-5">
                  <div>
                    <Field
                      label="Search By Blog Name"
                      name="title"
                      type="text"
                      placeholder="Enter blog name"
                      component={InputField}
                      onChange={(e) => {
                        const newTitle = e.target.value;
                        setFieldValue("title", newTitle);
                        handleSearch({ ...values, title: newTitle }); // Update and filter
                      }}
                    />
                  </div>
                  {/* <div>
          <label
            htmlFor="status"
            className="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
          >
            Status
          </label>
          <Field
            name="status"
            selectorOptions={statusOptions}
            selectorLabelKey="label"
            selectorValueKey="value"
            placeholder="Select status"
            component={SelectField}
            onChange={(value) => {
              setFieldValue("status", value);
              handleSearch({ title: values.title, status: value }); // Use updated title and new status
            }}
          />
        </div> */}
                </div>
              </Form>
            )}
          </Formik>

        </div>

        <DataTable
          loadingMessage="Loading..."
          isLoading={loading}
          columns={columns}
          data={blogs}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />

        {isEditModalVisible && (
          <EditModal
            blog={selectedBlog}
            onClose={() => setEditModalVisible(false)}
            onUpdate={fetchBlogs}
          />
        )}

        {isDeleteModalVisible && selectedBlog && (
          <DeleteBlog
            id={selectedBlog._id}
            onDelete={handleDeleteBlog}
            message="Are you sure you want to delete this blog?"
            onClose={() => setDeleteModalVisible(false)}
          />
        )}
      </Card>
    </>
  );
};

export default AllBlogs;
