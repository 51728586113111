import React from "react";
import DatePicker from "react-datepicker";
import { Field, ErrorMessage } from "formik";
import "react-datepicker/dist/react-datepicker.css";
const DatePickerField = ({ field, form, ...props }) => {
  const { setFieldValue } = form;
  const { name, value } = field;

  return (
    <div>
      <DatePicker
        {...field}
        {...props}
        selected={value}
        onChange={(val) => setFieldValue(name, val)}
        dateFormat="yyyy/MM/dd"
      />
      <ErrorMessage name={name} component="div" className="error-text" />
    </div>
  );
};

export default DatePickerField;
