import React from "react";
import { useDropzone } from "react-dropzone";
import { FiDownloadCloud } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Card, Col, Label, Row } from "reactstrap";

const DropZone = (props) => {
  const {
    setFieldValue,
    values,
    label,
    name,
    accept,
    errors,
    children,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || undefined,
    onDrop: (uploadedImages) => {
      handleImageChange(uploadedImages, setFieldValue);
    },
  });

  const handleImageChange = (uploadedImages, setFieldValue) => {
    const file = uploadedImages[0]; // Only take the first file
    const maxSize = 75 * 1024 * 1024; // 75 MB

    if (file && file.size > maxSize) {
      alert("File size exceeds the maximum limit of 75 MB.");
      return;
    }

    if (name) {
      setFieldValue(name, file);
    }
  };
  const renderUploadedImage = () => {
    const image = values[name];
  
    if (!image) return null;
  
    // Check if image is a File object
    const imageSrc = typeof image === "string" ? image : (image instanceof File ? URL.createObjectURL(image) : null);
  
    return (
      <div className="uploaded-image" style={{ marginRight: "20px" }}>
        <img
          src={imageSrc}
          onError={(e) => (e.currentTarget.src = "/assets/images/doc-dummy.png")}
          alt="Uploaded"
          style={{
            width: "200px",
            height: "200px",
            objectFit: "cover",
            borderRadius: "12px",
            marginBottom: "20px",
          }}
        />
        <div className="flex" style={{ marginLeft:'20px',arginTop: "-10px" }}>
          <IoMdClose
            onClick={(e) => {
              e.preventDefault();
              setFieldValue(name, null); // Clear the image
            }}
            color="red"
            size={25}
          />
        </div>
      </div>
    );
  };
  

  return (
    <React.Fragment>
      <Col md="12" sm="12">
        <Label className="ml-3 text-sm font-bold text-navy-700 dark:text-white" for={label}>
          {label}
        </Label>
        <Row className="import-component">
          <Col sm="12">
            <Card>
              <div>
                <Row>
                  <Col sm="12">
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        height: "352px",
                        border: errors ? "1px solid #ea5455" : "",
                      }}
                    >
                      <input {...getInputProps()} />
                      <div className="d-flex align-items-center justify-content-center flex-column pt-28">
                        <FiDownloadCloud size={64} />
                        <h5>Drop Image here or click to upload</h5>
                        <p className="text-secondary">
                          Drop an image here or click{" "}
                          <a href="/" onClick={(e) => e.preventDefault()}>
                            browse
                          </a>{" "}
                          through your machine
                        </p>
                      </div>
                    </div>
                    {errors && <p className="text-danger" style={{ marginTop: "7px" }}>{errors}</p>}
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
      {children ? (
        <>{children}</>
      ) : (
        <div className="uploaded-images-container" style={{ width: "100%" }}>
          {renderUploadedImage()}
        </div>
      )}
    </React.Fragment>
  );
};

export default DropZone;
