import React from "react";
import { Field, Form, Formik } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputField from "components/fields/InputField";
import { Button } from "components/ui-elements/Button";
import { put } from "services/api_Helpers";

const EditModal = ({ coupon, onClose, onUpdate }) => {
    const updateCoupon = async (values) => {
        console.log('Inside updateCoupon function:', values);
        try {
            const response = await put(`/coupon/${coupon._id}`, values);
            console.log('Coupon updated successfully:', response);
            onUpdate();
            onClose();
        } catch (error) {
            console.error("Error updating coupon:", error);
        }
    };

    return (
        <Modal
            isOpen={true}
            toggle={onClose}
            size="lg"
            className="modal-dialog-centered"
        >
            <ModalHeader toggle={onClose}>Edit Coupon</ModalHeader>
            <Formik
                initialValues={{
                    title: coupon?.title || "",
                    couponCode: coupon?.couponCode || "",
                    discountPercentage: coupon?.discountPercentage || 0,
                    startDate: coupon?.startDate ? coupon.startDate.split("T")[0] : "",
                    expiryDate: coupon?.expiryDate ? coupon.expiryDate.split("T")[0] : "",
                    noOfCoupons: coupon?.noOfCoupons || 0,
                }}
                onSubmit={(values, actions) => {
                    console.log('Formik onSubmit triggered:', values);
                    updateCoupon(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={(e) => {
                        console.log('Form submit triggered');
                        handleSubmit(e);
                    }}>
                        <ModalBody>
                            {/* Row for Title and Coupon Code */}
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        label="Title"
                                        name="title"
                                        type="text"
                                        component={InputField}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        label="Coupon Code"
                                        name="couponCode"
                                        type="text"
                                        component={InputField}
                                    />
                                </div>
                            </div>

                            {/* Row for Discount Percentage and Number of Coupons */}
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        label="Discount Percentage"
                                        name="discountPercentage"
                                        type="number"
                                        component={InputField}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        label="Number of Coupons"
                                        name="noOfCoupons"
                                        type="number"
                                        component={InputField}
                                    />
                                </div>
                            </div>

                            {/* Row for Start Date and Expiry Date */}
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        label="Start Date"
                                        name="startDate"
                                        type="date"
                                        component={InputField}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        label="Expiry Date"
                                        name="expiryDate"
                                        type="date"
                                        component={InputField}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit"

                                className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-700"
                            >Save</button>
                            <button type="button" onClick={onClose} variant="outline"
                                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700">
                                Cancel
                            </button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditModal;
