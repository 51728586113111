// components/AppModal/DeleteModal.jsx
import React from 'react';

const DeleteCoupon = ({ id, onDelete, message, onClose }) => {
  console.log('DeleteCoupon rendered with:', { id, message, onDelete, onClose });

  const handleConfirm = () => {
    console.log('handleConfirm called');
    if (onDelete) {
      console.log('Calling onDelete with id:', id);
      onDelete(id);
    } else {
      console.error('onDelete function is not provided');
    }
  };

  const handleCancel = () => {
    console.log('handleCancel called');
    if (onClose) {
      console.log('Calling onClose');
      onClose();
    } else {
      console.error('onClose function is not provided');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50">
      <div className="bg-white p-6 rounded shadow-lg">
        <p>{message}</p>
        <div className="mt-4 flex justify-end gap-2">
          <button
            onClick={handleCancel}
            className="bg-white text-black border border-gray-300 py-2 px-4 rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCoupon;
