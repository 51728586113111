import React from "react";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import {
  FaBriefcase,
  FaList,
  FaLocationArrow,
  FaQuestionCircle,
  FaUsers,
} from "react-icons/fa";

// Auth Imports
import SignIn from "./views/auth/SignIn";

// Icon Imports
import { MdHome, MdPerson, MdLock } from "react-icons/md";
import { FaHeartbeat } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { FaGavel } from "react-icons/fa6";
import FAQs from "views/admin/Faqs/Faqs";
import SignUp from "views/auth/SignUp";
import { HiUsers } from "react-icons/hi";
import { RiUserSharedFill } from "react-icons/ri";
import { FaFileSignature } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import Price from 'views/admin/notary/Price'
// local imports


import AllBlogs from "views/admin/blogs/AllBlogs";

import AddBlogs from "views/admin/blogs/AddBlogs";
import AddPrice from "views/admin/notary/AddPrice";
import AddCoupon from "views/admin/coupons/AddCoupon"
import AllCoupon from "views/admin/coupons/AllCoupons"


import ProtectedRoutes from "components/common/protected-route";
import AllAffirmations from "views/admin/affirmations/AllAffirmations";
import PrivacyPolicy from "views/admin/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "views/admin/termsCondtion/termsAndCondtion";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "dashboard",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: (
  //     <ProtectedRoutes>
  //       <MainDashboard />
  //     </ProtectedRoutes>
  //   ),
  // },
  {
    name: "All Affirmations",
    layout: "/admin",
    path: "affirmations",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoutes>
        <AllAffirmations />
      </ProtectedRoutes>
    ),
  },

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
 
  {
    header: true,
    name: "Price",
    icon: <FaGavel className="h-5 w-6" />,
    layout: "/admin",
    childrens: [
  {
    // header: true,
    name: "Add Price",
    layout: "/admin",
    path: "add-price",

    icon: <FaFileSignature className="h-5 w-6" />,
    component:<ProtectedRoutes> <AddPrice /></ProtectedRoutes>,
  },
  {
    // header: true,
    name: "Price",
    layout: "/admin",
    path: "prices",

    icon: <FaFileSignature className="h-5 w-6" />,
    component:<ProtectedRoutes> <Price /></ProtectedRoutes>,
  },
]
},
   
  {
    header: true,
    name: "Blogs",
    icon: <FaGavel className="h-5 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Blogs",
        layout: "/admin",
        path: "all-blogs",
        icon: <FaList className="h-4 w-6" />,
        component:<ProtectedRoutes> <AllBlogs /></ProtectedRoutes>,
      },

      {
        name: "Add Blogs",
        layout: "/admin",
        path: "add-blog",
        icon: <MdAddCircle className="h-4 w-6" />,
        component:<ProtectedRoutes> <AddBlogs /></ProtectedRoutes>,
      },
    ],
  },

  {
    header: true,
    name: "Coupons",
    icon: <FaGavel className="h-5 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Coupons",
        layout: "/admin",
        path: "all-coupons",
        icon: <FaList className="h-4 w-6" />,
        component:<ProtectedRoutes> <AllCoupon /></ProtectedRoutes>,
      },

      {
        name: "Add Coupon",
        layout: "/admin",
        path: "add-coupon",
        icon: <MdAddCircle className="h-4 w-6" />,
        component:<ProtectedRoutes> <AddCoupon /></ProtectedRoutes>,
      },
    ],
  },
  {
    name: "Privacy Policy",
    layout: "/admin",
    path: "privacy-policy",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoutes>
        <PrivacyPolicy />
      </ProtectedRoutes>
    ),
  },
  {
    name: "Terms and Condition",
    layout: "/admin",
    path: "terms-condition",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoutes>
        <TermsAndConditions />
      </ProtectedRoutes>
    ),
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock />,
    component: <SignIn className="h-6 w-6" />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock />,
    component: <SignUp className="h-6 w-6" />,
  },



  //!! useful components exits in this routes
  // {
  //   header: true,
  //   name: "Notary",
  //   layout: "/admin",
  //   path: "notary",
  //   icon: <FaBlog  />,
  //   childrens: [
  //     {
  //       name: "All Categories",
  //       layout: "/admin",
  //       path: "all-categories",
  //       icon: <CiCircleList  />,
  //       component: <BlogCategories />,
  //     },
  //     {
  //       name: "All Services",
  //       layout: "/admin",
  //       path: "all-professionals",
  //       icon: <CiCircleList  />,
  //       component: <Blogs />,
  //     },
  //     {
  //       name: "Add Category",
  //       layout: "/admin",
  //       path: "add-category",
  //       icon: <IoAddOutline  />,
  //       component: <AddBlog />,
  //     },
  //   ],
  // },

  // {
  //   name: "FAQs",
  //   layout: "/admin",
  //   path: "faqs",
  //   icon: <FaQuestionCircle className="h-5 w-6" />,
  //   component:<ProtectedRoutes> <FAQs /></ProtectedRoutes>,
  // },
];
export default routes;
