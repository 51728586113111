import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { authInstance } from "services/api_Helpers"; // API instance
import { useDispatch } from "react-redux";
import { handleLogin } from "../../redux/slices/authSlice"; // Redux action
import { successToast, errorToast } from "utils"; // Toast notifications
import { FcGoogle } from "react-icons/fc";
import { post } from "services/api_Helpers";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords must match")
      .required("Confirm Password is required"),
    terms: Yup.boolean()
      .oneOf([true], "You must agree with terms and conditions"),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await post("/register", {
        email: values.email,
        password: values.password,
      });
      const { data, message } = response.data;
      const { user, token } = data;

      dispatch(handleLogin({ user, token }));
      successToast(message);
      navigate("/admin/dashboard", { replace: true });
    } catch (error) {
      errorToast("Sign Up failed. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Sign Up by going through the below process!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign Up with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <div>
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Email*"
                  placeholder="mail@simmmple.com"
                  id="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message text-red-600"
                />
              </div>

              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error-message text-red-600"
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Confirm Password*"
                placeholder="Min. 8 characters"
                id="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error-message text-red-600"
              />

              <div className="mb-4">
                <div className="flex items-center">
                  <Checkbox
                    id="terms"
                    name="terms"
                    checked={values.terms}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Agree with terms and conditions
                  </p>
                </div>
                <ErrorMessage
                  name="terms"
                  component="div"
                  className="error-message text-red-600"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                {isSubmitting ? "Signing Up..." : "Sign Up"}
              </button>
            </Form>
          )}
        </Formik>
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have account?
          </span>
          <Link
            to="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}
