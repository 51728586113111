import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import InputField from 'components/fields/InputField';
import { successToast, errorToast } from 'utils';
import { ErrorMessage } from 'components/common/error-msg';
import { useNavigate } from 'react-router-dom';
import Card from 'components/card';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { get, post, put } from 'services/api_Helpers'; // Ensure these methods are available

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [privacyPolicy, setPrivacyPolicy] = useState({
    heading: '',
    content: '',
  });
  const [isDataExisting, setIsDataExisting] = useState(false);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await get('/terms-and-conditions');
        if (response.data.success) {
          setPrivacyPolicy(response.data.data);
          setIsDataExisting(true); 
        } else {
          setIsDataExisting(false); // Set to false if no data found
        }
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
        setIsDataExisting(false); // Ensure this is set to false on error
      }
    };

    fetchPrivacyPolicy();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const endpoint = '/terms-and-conditions'; // Same endpoint for both
      const method = isDataExisting ? put : post; // Determine method based on existence

      const res = await method(endpoint, values); // Use PUT or POST based on existence of data

      successToast(res.data.message);
      resetForm();
      
      // Re-fetch data after submission to get the updated data
      const updatedResponse = await get('/terms-and-conditions');
      if (updatedResponse.data.success) {
        setPrivacyPolicy(updatedResponse.data.data);
      }

      navigate('/admin/terms-condition');
    } catch (error) {
      errorToast(error.response?.data?.message || error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    heading: Yup.string().required('Heading is required'),
    content: Yup.string().required('Content is required'),
  });

  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">Privacy Policy</h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <Formik
          initialValues={privacyPolicy}
        //   validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                <div className="px-3 py-3">
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Field
                          label="Heading"
                          placeholder="Enter the heading"
                          type="text"
                          name="heading"
                          component={InputField}
                        />
                        <ErrorMessage error={errors.heading} />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <label className="text-sm text-navy-700 dark:text-white ml-3 font-bold mb-2">
                          Content
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('content', data);
                          }}
                          config={{
                            height: '500px', // Adjust the height here
                          }}
                        />
                        {errors.content && touched.content ? (
                          <ErrorMessage error={errors.content} />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default TermsAndConditions;
