import axios from "axios";

// export const ImagebaesURL = 'http://localhost:5000/';
// const baesURL = 'http://localhost:5000/api/v1';

export const ImagebaesURL = 'https://yourownaffirmations.com/backend/';
const baesURL = 'https://yourownaffirmations.com/backend/api/v1';
console.log("🚀 ~ baesURL:", baesURL)

const axiosInstance = axios.create({
  baseURL: `${baesURL}/`,
});

export const authInstance = axios.create({
  baseURL: `${baesURL}/auth`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const get = (api) => axiosInstance.get(api);
export const post = (api, body, config) =>
  axiosInstance.post(api, body, config);
export const postForm = (url, body, options) =>
  axiosInstance.post(url, body, {
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "multipart/form-data", // Set Content-Type for the specific request
    },
  });

export const putForm = (url, body, options) =>
  axiosInstance.put(url, body, {
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "multipart/form-data", // Set Content-Type for the specific request
    },
  });
export const put = (api, body, config) => axiosInstance.put(api, body, config);
export const deleted = (api, body) => axiosInstance.delete(api, { data: body });
export const del = (api, body) => axiosInstance.delete(api, { data: body });

export const patch = async (url, body, config = {}) =>
  axiosInstance.patch(url, body, config);
