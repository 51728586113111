import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "utils/hooks/user";
const ProtectedRoutes = ({ children }) => {
  const { isAuthenticated } = useAuth();
  let location = useLocation();
  // if (!isAuthenticated) {
  //   return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
  // }
  return children;
};
export default ProtectedRoutes;

